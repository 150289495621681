import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Fade from 'react-reveal/Fade'

import Layout from '../components/Layout'
import Banner from '../components/Banner'
import ArticleCard from '../components/ArticleCard'

const getYear = value => {
  return value.slice(value.length - 4)
}

const NewsPage = ({ data, location }) => {
  const pageData = data.prismicNews.data
  const allArticles = data.allPrismicNewsArticle.edges

  const [years, setYears] = useState()
  const [selectedYear, setSelectedYear] = useState()
  const [articles, setArticles] = useState(allArticles)

  const filterArticles = value => {
    let articles = allArticles
    if (value !== 'all') {
      articles = allArticles.filter(article => {
        return getYear(article.node.data.date) === value
      })
    }

    setSelectedYear(value)
    setArticles(articles)
  }

  useEffect(() => {
    const yearsArray = allArticles.map(item => {
      return getYear(item.node.data.date)
    })

    var uniqueYears = [...new Set(yearsArray)]
    setYears(uniqueYears)
  }, [allArticles])

  return (
    <Layout
      seoTitle={pageData.meta_title || pageData.title}
      seoDescription={pageData.meta_description}
      location={location}
    >
      <Banner title={pageData.title} image={pageData.hero_image} hero />
      <div className="px-ogs">
        <div className="wrapper">
          {years && (
            <div className="flex items-center justify-center md:justify-end space-x-gs text-xxs uppercase tracking-widest pb-15 lg:pb-30">
              <span>Year</span>
              <div className="select w-32 lg:w-44">
                <select
                  value={selectedYear}
                  onBlur={e => {
                    filterArticles(e.target.value)
                  }}
                  onChange={e => {
                    filterArticles(e.target.value)
                  }}
                  className="appearance-none select__inner"
                >
                  <option value="all">All</option>
                  {years.map((item, index) => {
                    return (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    )
                  })}
                </select>
              </div>
            </div>
          )}
          {articles.length > 0 ? (
            <div className="flex flex-wrap -ml-gs -mb-15">
              {articles.map((item, index) => {
                return <ArticleCard node={item.node} key={index} />
              })}
            </div>
          ) : (
            <Fade ssrReveal delay={250} duration={2250}>
              <div className="flex w-full py-20 justify-center">
                <div className="w-1/2 text-lg text-center">
                  There are currently no articles, check back later to see more.
                </div>
              </div>
            </Fade>
          )}
        </div>
      </div>
    </Layout>
  )
}

NewsPage.propTypes = {
  location: PropTypes.object.isRequired,
}

export default NewsPage

export const query = graphql`
  query {
    prismicNews {
      data {
        meta_title
        meta_description
        hero_image {
          alt
          localFile {
            childImageSharp {
              fluid(maxWidth: 2400, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        title
      }
    }
    allPrismicNewsArticle(sort: { order: DESC, fields: data___date }) {
      edges {
        node {
          data {
            image {
              alt
              localFile {
                childImageSharp {
                  fluid(maxWidth: 900, maxHeight: 900, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
            title
            date(formatString: "MMMM Do, YYYY")
          }
          uid
          url
        }
      }
    }
  }
`
